import React from "react";
import { GatsbySeo } from "gatsby-plugin-next-seo";

const SymposiumRegistrationSuccessPage = () => {
  return (
    <div className="h-screen w-full">
      <GatsbySeo
        title="Registration Successful"
        description="Registration Successful"
      />
      <div
        className="flex flex-col items-center relative h-full w-full"
        style={{ fontFamily: "Playfair Display" }}>
        <img
          alt="stream-background"
          src="/icons/symposium/stream-background.svg"
          className="absolute object-cover top-0 w-full h-full -z-10"
        />
        <h1 className="text-deep-blue-600 text-sm-h2 mx-sm-subtopic text-center lg:mx-0 leading-8 lg:text-lg-h2 lg:leading-16 my-sm-content lg:my-lg-subtopic">
          REGISTRATION SUCCESSFUL!
        </h1>
        <h2 className="text-deep-blue-600 text-sm-h3-card mx-sm-subtopic text-center lg:mx-0 leading-8 lg:text-lg-h3-card my-sm-content lg:my-lg-subtopic" style={{ fontFamily: "Francois One" }}>
          Please check the email we sent to your mailbox for more details. Thank you for your registration!
        </h2>
      </div>
    </div>
  );
};

export default SymposiumRegistrationSuccessPage;

